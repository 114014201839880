@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";



/* base styles */

@font-face {
  font-family: "Uni Sans";
  src: local("Uni Sans Regular"),
   url("./fonts/Uni Sans Regular.otf") format("truetype");
  
  }

 .card{
   border-radius: 50px!important;
   border: 'primary' ;
 }

 .card .button{ 
   border: 100px;
 }

* {
  margin: 0;
  font-family: "Uni Sans";
  align-items: center;
  
}
h1 {
  margin: auto;
  width: 50%;
  text-align: center;
  padding: 10px;
}
h2 {
  margin: auto;
  
  text-align: center;
  padding: 10px;
}
.navbar {
  
  padding: 0px;
  font-size: 17px;
  display: flex;
  align-items: center;
  
  margin-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  color: #6abaf3;
}
.navbar .links {
  display:flex;
  
}
.navbar a {
  display:flex;
  margin-left: 100px;
  text-decoration: none;
  padding: 6px;  
}
.navbar a:hover {
  color: #6abaf3;
  
}

.content {
  max-width: 1000px;
  margin: auto;
  
  align-items: center;
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}
#main {
  margin: auto;
  width: 800px;
}

#sidebar    {
  margin-left:-25px;
  margin-top:250px;
  width: 200px;
  float: left;
}

#page-wrap  {
  width: 600px;
  border: 1px solid #000;
  float: left;
}

.clear:after {
  clear: both;
  display: table;
  content: "";
}

.ralign{

  float: right;
  font-size:30px;
}
.box{
  border:20px;
  padding-bottom:55px;

}
.box:hover{
  background-color: rgba(240, 240, 238, 0.61);
  opacity: 1;

}
.lalign{
  float: left;
  font-size:30px;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.cteam:hover {
  background-color: rgba(240, 240, 238, 0.61);
  border-radius: 50px!important;
  border-width: 1px;

}

.hometext {
  color: #fff;
  max-width:1800px;
  align-items: left;
  margin-top:90px;
  line-height: 120px;
  font-size: 130px;
  padding-bottom:20px;
  font-weight: 900;
}

.about{
  padding: 10px 16px;
  margin: 20px;
  border: 2px solid #6abaf3;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
}

.about:hover{
  box-shadow: 1px 1px 3px rgba(0,0,0,1);
}

.about h2{
  font-size: 30px;
  color: #6abaf3;
  margin-bottom: 8px;
  text-align: center;
}


.loginform {
  width: 500px;
  background-color: #fff;
  color:#000;
  margin: 0 auto;
  text-align: center;
  border: 2px solid #ddd;
  padding-top:30px;
  padding-bottom:30px;
  padding-left:100px;
  padding-right:100px;
  border-radius: 10px;
}
.login label {
  width: 100%;
  
  text-align: left;
  display: block;
  color: #000000;
}
.login h2 {
  font-size: 20px;
  color: #000000;
  margin-bottom: 30px;
  text-align: center;
}
.login input, .login textarea, .login select {
  width: 100%;
  color: #000000;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}
.login button {
  background: #fff;
  color: #000;
  border: 2;
  padding: 8px;
  border-radius: 10px;
  border-color: #000;
  cursor: pointer;
}

.dashboard button:hover{
  box-shadow: 1px 1px 3px rgba(0,0,0,1);

}

.dashboard div {
  display: flex;
}

.innerdash a {
  flex: 1;
  font-size: 30px;
  color: #000000;
  border: 1px solid #ddd;
  width: 100%;
  padding: 25px;
  background: #fff;
  margin: 10px;
  border-radius: 10px;
}

.dashboard a:hover{
  box-shadow: 1px 1px 3px rgba(0,0,0,1);
}


.tabs {
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-btn {
  display: block;
  flex: 1;
  padding: 8px 20px;
  background: #eee;
  border: 0;
}

.tab-indicator-container {
  display: flex;
  background: #eee;
  border: 10px;
  margin-bottom: 10px;
  
}

.tab-indicator {
  height: 3px;
  background: #000;
  transition: transform 0.2s;
}

.tab-content {
  padding: 10px;
  border: 2px solid #6abaf3;  
  border-radius: 10px;  
}